// Moment format
export const MOMENT_DATETIME_SECONDS_FORMAT = 'MMM. D, YYYY, h:mm:ssa' // Jan. 1, 2000, 3:15:10pm
export const MOMENT_DATETIME_FORMAT = 'MMM D, YYYY, h:mma' // Jan 1, 2000, 3:15pm
export const MOMENT_DATE_FORMAT = 'MMM D, YYYY' // Jan 1, 2000
export const MOMENT_SHORTDATE_FORMAT = 'MMM D' // Jan 1
export const MOMENT_TIME_FORMAT = 'h:mma' // 3:15pm
export const MOMENT_TIMESECONDS_FORMAT = 'h:mm:ssa' // 3:15:39pm

// VISITS
export const VISITS_PAGINATION_LIMIT = 20

// Form
export const PASSWORD_MIN_LENGTH = 8 // characters

// Transcription
export const TRANSCRIPTION_MIN_LENGTH = 100 // characters

// Subscriptin
export const TRIAL_PERIOD = 14 // days

// Transcriber
export type Language = 'en-US' | 'es-US' | 'fr-CA' | 'zh-CN' | 'hi-IN' | 'ta-IN' | 'pa-IN' | 'ur-IN' | 'ar-SY' | 'uk-UA' | 'ru-RU' | 'fil-PH' | 'ja-JP' | 'ko-KR' // English, Spanish, French, Chinese, Hindi, Tamil, Punjabi, Urdu, Arabic, Ukrainian, Russian, Filipino, Japanese, Korean
export const LANGUAGES: Language[] = ['en-US', 'es-US', 'fr-CA', 'zh-CN', 'hi-IN', 'ta-IN', 'pa-IN', 'ur-IN', 'ar-SY', 'uk-UA', 'ru-RU', 'fil-PH', 'ja-JP', 'ko-KR']
export const DEFAULT_LANGUAGE = 'en-US'
export const LANGUAGES_LABEL: Record<Language, string> = {
    'en-US': 'English',
    'es-US': 'Spanish',
    'fr-CA': 'French',
    'zh-CN': 'Chinese',
    'hi-IN': 'Hindi',
    'ta-IN': 'Tamil',
    'pa-IN': 'Punjabi',
    'ur-IN': 'Urdu',
    'ar-SY': 'Arabic',
    'uk-UA': 'Ukrainian',
    'ru-RU': 'Russian',
    'fil-PH': 'Filipino',
    'ja-JP': 'Japanese',
    'ko-KR': 'Korean',
}

export const JOIN_FULENT_EMAIL = (inviteLink: string) => {
    const subject = 'Join Fluent Health'
    const body = `Hi there,

I've been using Fluent Health, and I thought you'd love it too! It’s a great platform for managing health and wellness, helping you stay on top of your health goals effortlessly.

Sign up using my referral link to get started: ${inviteLink}

Looking forward to seeing you on Fluent Health!

Best,
[Your Name]`

    return [subject, body]
}
