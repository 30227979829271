import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useCallback, useState } from 'react'
import styled from 'styled-components'

import { SubscriptionCancellationFeedback } from '../../services/models/Subscription.model'
import { Button } from './Button'
import { Input } from './Input'

export interface CancelSubscriptionFeedbackModalProps {
    isOpen?: boolean
    onClose?(): void
    onSubmit(feedback: SubscriptionCancellationFeedback, comments: string): void
}

const Styles = styled.div`
    button {
        min-height: unset;
        padding: 6px 10px;
    }
`

const feedbackOptions: {
    value: SubscriptionCancellationFeedback
    label: string
}[] = [
    { value: 'too_expensive', label: 'Too expensive' },
    { value: 'missing_features', label: 'Missing features' },
    { value: 'switched_service', label: 'Switched to another service' },
    { value: 'low_quality', label: 'Low quality of service' },
    { value: 'too_complex', label: 'Too complex to use' },
    { value: 'unused', label: 'Not using it enough' },
    { value: 'customer_service', label: 'Poor customer service' },
    { value: 'other', label: 'Other' },
]

export const CancelSubscriptionFeedbackModal = ({
    isOpen,
    onClose,
    onSubmit,
}: CancelSubscriptionFeedbackModalProps) => {
    const [feedback, setFeedback] =
        useState<SubscriptionCancellationFeedback | null>(null)
    const [comments, setComments] = useState<string>('')

    const onSubmitHandler = useCallback(() => {
        if (feedback && onSubmit) {
            onSubmit(feedback, comments)
        }
        onClose?.()
    }, [feedback, comments, onSubmit, onClose])

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose!}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <Styles className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-semibold leading-6 text-gray-900 mb-4"
                                    >
                                        We&apos;re sorry to see you go
                                    </Dialog.Title>
                                    <p className="text-sm text-gray-500 mb-4">
                                        Please let us know why you&apos;re
                                        cancelling your subscription. Your
                                        feedback helps us improve our service.
                                    </p>
                                    <div className="space-y-2">
                                        {feedbackOptions.map((option) => (
                                            <div
                                                key={option.value}
                                                className="flex items-center"
                                            >
                                                <input
                                                    type="radio"
                                                    id={option.value}
                                                    name="cancellation-reason"
                                                    value={option.value}
                                                    checked={
                                                        feedback ===
                                                        option.value
                                                    }
                                                    onChange={() =>
                                                        setFeedback(
                                                            option.value
                                                        )
                                                    }
                                                    className="h-4 w-4 text-primary border-gray-300 focus:ring-primary"
                                                />
                                                <label
                                                    htmlFor={option.value}
                                                    className="ml-2 text-sm text-gray-700"
                                                >
                                                    {option.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>

                                    <Input
                                        className="mt-4"
                                        name="comments"
                                        placeholder="Additional comments (optional)"
                                        initialValue={comments}
                                        multiline
                                        onChange={(value) => setComments(value)}
                                    />
                                </div>

                                <div className="mt-8 flex justify-end">
                                    <Button
                                        intent="text"
                                        className="mr-3"
                                        onClick={onClose}
                                        label="Cancel"
                                    />
                                    <Button
                                        intent="danger"
                                        onClick={onSubmitHandler}
                                        label="Cancel subscription"
                                        disabled={!feedback}
                                    />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Styles>
            </Dialog>
        </Transition.Root>
    )
}
