import {
    ArrowLongRightIcon,
    ChevronLeftIcon,
    MicrophoneIcon,
    QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { DEFAULT_LANGUAGE, Language } from '../../constants'
import { useAppContext } from '../../hooks/useAppContext'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import {
    OpenMicrophonesModal,
    VisitCreated,
} from '../../services/event-bus/events'
import { useDeveloperContext } from '../Settings/Developer/hooks/useDeveloperContext'
import { Button } from '../UI/Button'
import { CreateVisit } from '../UI/CreateVisit'
import { LanguagesListBox } from '../UI/LanguagesListBox'
import { Loader } from '../UI/Loader'
import { TemplatesListBox } from '../UI/TemplatesListBox'
import { Toggle } from '../UI/Toggle'
import { Tooltip } from '../UI/Tooltip'
import { useVisitsContext } from '../Visits/hooks/useVisitsContext'

const Styles = styled.div``

export const WelcomeState = () => {
    const { isMobile } = useMediaQuery()
    const { showListView } = useVisitsContext().view
    const { preferences } = useAppContext().preferences
    const { isWhisperTranscriber, setAzureTranscriber, setWhisperTranscriber } =
        useDeveloperContext().developer
    const {
        templateId,
        customTemplateId,
        isTranslationEnabled,
        languageSource,
        languageTarget,
        setTemplateId,
        setlanguageTarget,
        setlanguageSource,
        setIsTranslationEnabled,
    } = useForm(
        isWhisperTranscriber,
        preferences?.defaultTemplateId,
        preferences?.defaultCustomTemplateId
    )

    const onSetLanguageTarget = useCallback(
        (language: Language) => {
            setlanguageTarget(language)

            // If Whisper transcriber is enabled with non-English language, then switch to Azure STT
            if (isWhisperTranscriber && language !== 'en-US') {
                setAzureTranscriber()
            }
        },
        [isWhisperTranscriber, setAzureTranscriber, setlanguageTarget]
    )

    return (
        <Styles className="bg-white h-full flex flex-col items-center justify-center text-center relative">
            {isMobile && (
                <div className="absolute inset-y-0 left-2 top-2">
                    <Button
                        intent="text"
                        label="Visits"
                        leftIconNode={
                            <ChevronLeftIcon className="h-5 w-5 text-gray-400" />
                        }
                        onClick={showListView}
                    />
                </div>
            )}

            <CreateVisit
                templateId={templateId}
                customTemplateId={customTemplateId}
                languageSource={
                    isTranslationEnabled ? languageSource : undefined
                }
                languageTarget={languageTarget}
                childNode={(isLoading) => (
                    <RecordButton isLoading={isLoading} />
                )}
                onCreated={() => VisitCreated.emit()}
                startRecordingOnLoad
            ></CreateVisit>

            <div className="mt-4 mb-8 text-lg text-gray-500">
                Start a new recording
            </div>

            <div className="w-full max-w-md p-3">
                <div className="flex justify-end mb-1">
                    <div
                        className="text-primary font-medium hover:cursor-pointer"
                        onClick={() => OpenMicrophonesModal.emit({})}
                    >
                        Microphone Settings
                    </div>
                </div>

                <div className="bg-gray-50 rounded-md border">
                    <OptionWrapper
                        className="border-b h-16"
                        labelNode="Template"
                        rightNode={
                            <TemplatesListBox
                                templateId={customTemplateId ?? templateId}
                                onChange={setTemplateId}
                            />
                        }
                    />

                    {isTranslationEnabled ? (
                        <>
                            <OptionWrapper
                                className="border-b h-16"
                                labelNode="Languages"
                                rightNode={
                                    <div className="flex items-center">
                                        <LanguagesListBox
                                            value={languageSource}
                                            onChange={setlanguageSource}
                                        />
                                        <ArrowLongRightIcon className="h-5 w-5 mx-1" />
                                        <LanguagesListBox
                                            value={languageTarget}
                                            onChange={setlanguageTarget}
                                        />
                                    </div>
                                }
                            />
                        </>
                    ) : (
                        <OptionWrapper
                            className="border-b h-16"
                            labelNode="Language"
                            tooltipContent="The language you and the patient will be speaking during the visit"
                            rightNode={
                                <LanguagesListBox
                                    value={languageTarget}
                                    onChange={onSetLanguageTarget}
                                />
                            }
                        />
                    )}

                    <OptionWrapper
                        className="h-16"
                        labelNode={
                            <div className="flex items-center">
                                <div>Real-time translation</div>
                                <div className="rounded-md bg-blue-50 px-2 py-1 text-xs font-semibold text-primary ring-1 ring-inset ring-primary ml-3">
                                    Beta
                                </div>
                            </div>
                        }
                        rightNode={
                            <Toggle
                                value={isTranslationEnabled}
                                onChange={(value) => {
                                    setIsTranslationEnabled(value)

                                    // If translation is disabled, then set default language source
                                    if (value) {
                                        setlanguageSource((prev) => {
                                            if (!prev) {
                                                return DEFAULT_LANGUAGE
                                            }
                                            return prev
                                        })
                                    }

                                    // Else set language source to undefined
                                    else {
                                        setlanguageSource(undefined)
                                    }

                                    // If translation is enabled, then switch to Azure STT
                                    if (value) {
                                        setAzureTranscriber()
                                    }

                                    // Else switch to Whisper transcriber, if applicable
                                    else if (isWhisperTranscriber) {
                                        setWhisperTranscriber()
                                    }
                                }}
                            />
                        }
                    />
                </div>
            </div>
        </Styles>
    )
}

const useForm = (
    isWhisperTranscriber: boolean,
    defaultTemplateId?: string,
    defaultCustomTemplateId?: string
) => {
    const [templateId, setTemplateId] = useState<string | undefined>(
        defaultTemplateId ?? undefined
    )
    const [customTemplateId, setCustomTemplateId] = useState<
        string | undefined
    >(defaultTemplateId ?? undefined)

    const [isTranslationEnabled, setIsTranslationEnabled] = useState(false)
    const [languageSource, setlanguageSource] = useState<Language | undefined>(
        undefined
    )
    const [languageTarget, setlanguageTarget] =
        useState<Language>(DEFAULT_LANGUAGE)

    useEffect(
        () => setTemplateId(defaultTemplateId ?? undefined),
        [defaultTemplateId]
    )
    useEffect(
        () => setCustomTemplateId(defaultCustomTemplateId ?? undefined),
        [defaultCustomTemplateId]
    )

    const setTemplateIdHander = useCallback(
        (_templateId: string, isCustomTemplate: boolean) => {
            setTemplateId(!isCustomTemplate ? _templateId : undefined)
            setCustomTemplateId(isCustomTemplate ? _templateId : undefined)
        },
        []
    )

    return {
        templateId,
        customTemplateId,
        setTemplateId: setTemplateIdHander,
        isTranslationEnabled,
        setIsTranslationEnabled,
        languageSource,
        setlanguageSource,
        languageTarget,
        setlanguageTarget,
    }
}

const RecordButton = ({ isLoading }: { isLoading: boolean }) => {
    return (
        <div className="rounded-full h-20 w-20 bg-red shadow-lg flex items-center justify-center cursor-pointer">
            {isLoading ? (
                <Loader className="h-6 w-6 text-white" />
            ) : (
                <MicrophoneIcon className="h-8 w-8 text-white" />
            )}
        </div>
    )
}

const OptionWrapperStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul {
        width: fit-content;
    }
`

const OptionWrapper = ({
    className,
    tooltipContent,
    labelNode,
    rightNode,
}: {
    className?: string
    tooltipContent?: string
    labelNode: ReactNode
    rightNode: ReactNode
}) => {
    if (tooltipContent) {
        return (
            <OptionWrapperStyles className={classNames('py-3 px-4', className)}>
                <div className="flex items-center">
                    <div className="text-sm font-medium">{labelNode}</div>

                    <Tooltip content={tooltipContent} placement="top">
                        <QuestionMarkCircleIcon className="h-5 w-5 text-gray-500 ml-1" />
                    </Tooltip>
                </div>
                {rightNode}
            </OptionWrapperStyles>
        )
    }

    return (
        <OptionWrapperStyles className={classNames('py-3 px-4', className)}>
            <div className="text-sm font-medium">{labelNode}</div>
            {rightNode}
        </OptionWrapperStyles>
    )
}
