import { ChatBubbleLeftRightIcon, PencilSquareIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { ReactNode, useState } from 'react'
import styled from 'styled-components'

import { useMediaQuery } from '../../../../../hooks/useMediaQuery'
import { Button } from '../../../../UI/Button'
import { ButtonsLayout } from '../styles'
import { DeleteButton } from './DeleteButton'
import { DurationDisplay } from './DurationDisplay'
import { FinishButton } from './FinishButton'
import { RecordButton } from './RecordButton'
import { SettingsButton } from './SettingsButton'

interface Props {
    className?: string
    visitId: string
    transcriptsListNode: ReactNode
    notepadNode: ReactNode
}

const Styles = styled.div``

export const Recorder = ({ className, visitId, transcriptsListNode, notepadNode }: Props) => {
    const { isMobile } = useMediaQuery()
    const [showTranscripts, setShowTranscripts] = useState<boolean>(false)
    const [showNotepad, setShowNotepad] = useState<boolean>(false)

    return (
        <Styles className={classNames(className, 'flex flex-1 flex-col items-center')}>
            <div className={classNames('flex gap-2', isMobile && 'absolute right-2 top-2', !isMobile && 'absolute right-10 top-6 z-10')}>
                {!showTranscripts && (
                    <Button
                        intent="text"
                        label={`${showNotepad ? 'Hide' : ''} Notepad`}
                        leftIconNode={<PencilSquareIcon className="h-5 w-5 text-gray-400" />}
                        onClick={() => setShowNotepad(!showNotepad)}
                    />
                )}
                {!showNotepad && (
                    <Button
                        intent="text"
                        label={`${showTranscripts ? 'Hide' : 'Show'} Transcripts`}
                        leftIconNode={<ChatBubbleLeftRightIcon className="h-5 w-5 text-gray-400" />}
                        onClick={() => setShowTranscripts(!showTranscripts)}
                    />
                )}
            </div>

            <DurationDisplay showMinimalDuration={showTranscripts || showNotepad} />
            {showTranscripts && transcriptsListNode}

            {showNotepad && notepadNode}

            <ButtonsLayout className="relative w-full flex justify-center">
                <DeleteButton visitId={visitId} />
                <RecordButton />
                <FinishButton visitId={visitId} />
                <SettingsButton className="absolute right-3 bottom-20 sm:bottom-0" />
            </ButtonsLayout>
        </Styles>
    )
}
